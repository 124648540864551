
import { ref, defineComponent, onMounted, computed, watch } from "vue";
import Camera from "simple-vue-camera";

export default defineComponent({
    components: {
        Camera
    },
    setup() {
        const fullscreenZIndex = ref(0);
        const view = ref("video");
        const camera = ref<InstanceType<typeof Camera>>();

        // Use camera reference to call functions
        const snapshot = async () => {
            const blob = await camera.value?.snapshot();
            console.log(JSON.stringify(blob));
            // To show the screenshot with an image tag, create a url
            const url = URL.createObjectURL(blob);
        }

        return {
            camera,
            snapshot, fullscreenZIndex,view
        }
    }
});
